import { Typography } from '@mui/material';

const NotFound = () => {
  return (
    <div>
      <Typography variant='h1'>Page not found!</Typography>
    </div>
  );
};

export default NotFound;
