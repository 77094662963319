import Login from './Login';

const LandingPage = () => {
  return (
    <div className='landing-img'>
      <Login />
    </div>
  );
};
export default LandingPage;
